import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";


type DomandaFields = {
    nome: string,
    cognome: string,
    cf: string,
    nascita: string,
    comunenascita: string,
    provincianascita: string,
    indirizzoresidenza: string,
    civicoresidenza: string,
    comuneresidenza: string,
    azienda: string,
    provinciaresidenza: string,
    capresidenza: string,
    capnascita: string,

    indirizzodomicilio: string,
    civicodomicilio: string,
    civicosede: string,
    capdomicilio: string,
    comunedomicilio: string,
    provinciadomicilio: string,

    telefono: string,
    cellulare: string,
    pec: string,
    qualitadi: string,

    cciaa: string,
    numcciaa: string,
    attivitadi: string,
    
    comunesede: string,
    indirizzosede: string,

    cfazienda: string,
    piva: string,

   
    dichiara1: boolean,
 
    dichiara3: boolean,
    dichiara4: boolean,

    dichiara5: boolean,
    dichiara6: boolean,
   
    dichiara11: boolean,
  
    allegati: []

}

const MAX_FILE_SIZE = 2000400; //100KB

const validFileExtensions = { image: ['jpg', 'pdf', 'tif', 'png', 'jpeg'] };

const yupSchema = Yup.object().shape({
    civicosede: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    nome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cognome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cf: Yup.string().required('Obbligatorio').matches(
        /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
        "Codice fiscale non conforme"
    ),
    nascita: Yup.string().required('Obbligatorio'),
    comunenascita: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    provincianascita: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    indirizzoresidenza: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    civicoresidenza: Yup.string().required('Obbligatorio').min(1, "inserire almeno 1 caratteri"),
    comuneresidenza: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    azienda:  Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),

    comunesede: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    indirizzosede: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
   
    cfazienda: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    piva: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),

    provinciaresidenza:  Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),

    cciaa: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    numcciaa: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    attivitadi: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio'),

    telefono : Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    dichiara1: Yup.boolean().isTrue(),

    dichiara3: Yup.boolean(),
    dichiara4: Yup.boolean(),
    dichiara5: Yup.boolean(),
    dichiara6: Yup.boolean(),
  
    dichiara11: Yup.boolean().isTrue(),
  

    allegati: Yup.array().of(

        Yup.object().shape({

    
            documento: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
          

        })
    ),

})

const valoriIniziali: DomandaFields = {
    civicosede:"",
    nome: "",
    cognome: "",
    cf: "",
    nascita: "",
    comunenascita: "",
    comuneresidenza: "",
    azienda: "",
    provinciaresidenza: "",
    provincianascita: "",
    indirizzoresidenza: "",
    civicoresidenza: "",
    capresidenza: "",
    capnascita: "",
    cciaa: "",
    numcciaa: "",
    attivitadi: "",
    indirizzodomicilio: "",
    civicodomicilio: "",
    capdomicilio: "",
    comunedomicilio: "",
    provinciadomicilio: "",
    telefono: "",
    cellulare: "",
    pec: "",
    qualitadi: "",
  
    comunesede: "",
    indirizzosede: "",
 
    cfazienda: "",
    piva: "",

     dichiara1: false,
 
    dichiara3: false,
    dichiara4: false,

    dichiara5: false,
    dichiara6: false,
  
    dichiara11: false,
    
    allegati: []

    //*/
}
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const aziendeEducamediamoci = () => {

    const [visual, setsetvisual] = useState(false)
    const [update, setupdate] = useState(false);
    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);
        //   nw.print();
        //   nw.close();
    }

    return (
        <> Termini scaduti
          {false &&  <Formik initialValues={valoriIniziali}
                validationSchema={yupSchema}
                validateOnBlur={false}
                validateOnChange={false}

                onSubmit={(values) => {

                    const fileInput = document.querySelectorAll('input[type="file"]');

                    const data = JSON.stringify({ ...values })
                    const fd = new FormData();
                    // append directly as part of the postData in plain text
                    fd.append('istanzajson', data);
                    fd.append('identificativo', "102078");
                    fd.append('nome', "Catalogo Fornitori");

                    fileInput.forEach((docItem) => {
                        fd.append(docItem.id, (document.getElementById(docItem.id) as HTMLInputElement).files[0]);
                    });

                    axios.post(API_URL_LARAVEL + '/istanza', fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    ).then((response) => {

                        console.log(response);

                        if (response.data.success) {
                            Toast.fire({
                                title: response.data.text,
                                icon: 'success'
                            });

                            window.location.href = "/esito?id=" + response.data.result;

                        }
                    }).catch((err) => {
                        console.log(err);

                        alert('Errore imprevisto. Provare a rinominare i file allegati senza utilizzare caratteri speciali');

                        document.getElementById('spinner').style.display = 'none';
                        document.getElementById('_conferma').style.display = 'inline';

                    });

                    //    alert(JSON.stringify(values));
                }}>
                {(formik: FormikProps<any>) => (
                    <div className="form">
                        <div className="form-group">
                            <div className="col-sm-12 col-sm-offset-1" >
                                <table>
                                    <tbody><tr>
                                        <td>
                                            <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                                <li className="active">
                                                    Istanza di partecipazione all’Avviso per la costituzione di una lista di operatori economici interessati alla fornitura di materiale didattico e attrezzatura informatica agli assegnatari dei buoni per il PROGETTO “EDUCAMEDIAMOCI”

                                                </li>
                                            </ol>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        </div>

                        {<div id="div_domanda">
                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati Anagrafici </div></div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='nome' label='Nome' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='cognome' label='Cognome' />
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cf'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cf' id='cf' onChange={event => formik.setFieldValue('cf', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cf' /> </small>

                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascita">Data di nascita (gg/mm/aaaa)</label>
                                        <input type="date" id="nascita" name="nascita" onChange={evt => {
                                            formik.setFieldValue('nascita', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascita1`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comunenascita' label='Comune di nascita' />
                                </div>
                                <div className="col-sm">

                                    <Field component={InputField} id='provincianascita' label='Provincia di nascita' />

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='comuneresidenza' label='Comune di residenza' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='provinciaresidenza' label='Provincia di residenza' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzoresidenza' label='Via di residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicoresidenza' label='Civico di residenza' />
                                </div>

                            </div>


                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>In qualità di Rappresentante di </div></div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='azienda' label='Denominazione Ente' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='comunesede' label='Comune sede' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzosede' label='Via  sede' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicosede' label='Civico  sede' />
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='cfazienda' label='Codice fiscale Ente' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='piva' label='Partita Iva Ente' />
                                </div>

                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Recapiti</div></div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='email'>e-mail</label>
                                        <input type="text" className="form-control" name='email' id='email' onChange={event => formik.setFieldValue('email', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='email' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='pec'>PEC </label>
                                        <input type="text" className="form-control" name='pec' id='pec' onChange={event => formik.setFieldValue('pec', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='pec' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='telefono' label='telefono' />
                                </div>

                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>MANIFESTA</div></div>
                            <p><strong>IL PROPRIO INTERESSE ALLA FORNITURA DI MATERIALE DIDATTICO E ATTREZZATURA INFORMATICA AGLI ASSEGNATARI DEI BUONI
                            </strong>
                            </p>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>DICHIARA</div></div>


                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cciaa'>di essere iscritto alla C.C.I.A.A. DI</label>
                                        <input type="text" className="form-control" name='cciaa' id='cciaa' onChange={event => formik.setFieldValue('cciaa', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cciaa' /> </small>
                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='numcciaa'>n. iscrizione</label>
                                        <input type="text" className="form-control" name='numcciaa' id='numcciaa' onChange={event => formik.setFieldValue('numcciaa', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='numcciaa' /> </small>
                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='attivitadi'>per l’attività di</label>
                                        <input type="text" className="form-control" name='attivitadi' id='attivitadi' onChange={event => formik.setFieldValue('attivitadi', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='attivitadi' /> </small>
                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <div className="form-check">

                                    <input id="dichiara1" type="checkbox" onChange={event => formik.setFieldValue('dichiara1', event.target.checked)} />

                                    <label htmlFor="dichiara1" >
                                        di non trovarsi in nessuna incapacità a contrarre con la Pubblica Amministrazione;
                                    </label>

                                    {formik.errors.dichiara1 && <small className="error">*Obbligatorio</small>}
                                </div>

                                <div className="form-check">

                                    <input id="dichiara3" type="checkbox" onChange={event => formik.setFieldValue('dichiara3', event.target.checked)} />

                                    <label htmlFor="dichiara3" >di essere in possesso delle necessarie autorizzazioni previste dalla vigente normativa per l’esercizio della propria attività;</label>

                                    {formik.errors.dichiara3 && <small className="error">*Obbligatorio</small>}
                                </div>

                                <div className="form-check">

                                    <input id="dichiara4" type="checkbox" onChange={event => formik.setFieldValue('dichiara4', event.target.checked)} />

                                    <label htmlFor="dichiara4" > di essere in regola con gli obblighi previdenziali ed assistenziali (DURC);

                                    </label>

                                    {formik.errors.dichiara4 && <small className="error">*Obbligatorio</small>}
                                </div>

                                <div className="form-check">

                                    <input id="dichiara5" type="checkbox" onChange={event => formik.setFieldValue('dichiara5', event.target.checked)} />

                                    <label htmlFor="dichiara5" >di essere in regola fiscalmente con l’Agenzia dell’Entrate;
                                    </label>

                                    {formik.errors.dichiara5 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara6" type="checkbox" onChange={event => formik.setFieldValue('dichiara6', event.target.checked)} />

                                    <label htmlFor="dichiara6" > di non applicare alcuna condizione per l’accettazione dei buoni spesa, né in riferimento ad un importo minimo da spendere in contanti, né all’applicazione di qualsivoglia riduzione percentuale.
                                    </label>

                                    {formik.errors.dichiara6 && <small className="error">*Obbligatorio</small>}
                                </div>

                            </div>


                            <>


                                <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>ACCONSENTE</div></div>

                                <div className="row">

                                    <div className="form-check">

                                        <input id="dichiara11" type="checkbox" onChange={event => formik.setFieldValue('dichiara11', event.target.checked)} />

                                        <label htmlFor="dichiara11" >
                                            ai sensi del Regolamento dell’Unione Europea 679/2016 (codice in materia di protezione dei dati personali), al trattamento dei propri dati anche personali, per le finalità inerenti la procedura di affidamento del servizio.
                                        </label>

                                        {formik.errors.dichiara11 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>

                                <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Allega</div></div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="documento" className="active">
                                        copia fotostatica di un documento di riconoscimento in corso di validità del legale rappresentante
                                        del soggetto candidato;
                                    </label>
                                    <input type="file" name="documento" id="documento" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.documento`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.documento`} /></small>

                                </div>


                                <div style={{ display: 'none' }} id='spinner' className="progress progress-indeterminate">
                                    <span className="visually-hidden">In elaborazione...</span>
                                    <div className="progress-bar" role="progressbar" />
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="btn-group col-sm-5 col-sm-offset-6 ">
                                        <button name="_anteprima" id="_anteprima" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150, display: 'none' }}

                                            onClick={() => {
                                                stampa_id('root');
                                                console.log(formik.errors);
                                            }}

                                        >Anteprima</button>
                                        {/**   <button name="_stampadomanda" id="_stampadomanda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Stampa domanda</button>
                                       */}  <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }} onClick={() => {

                                            // alert('invio');

                                            formik.validateForm().then(async res => {
                                                const { ob } = await res;

                                                let b = Object.keys(res).length;

                                                //       console.log('res', b, res);
                                                //     console.log(b);

                                                if (res.figli !== undefined) {

                                                    b = b - 1;

                                                    for (let i = 0; i < res.figli.length; i++) {
                                                        // @ts-ignore 
                                                        b += Object.keys(res.figli[i]).length;
                                                    }

                                                    console.log(b);
                                                }

                                                if (res.allegati !== undefined) {
                                                    b = b - 1;
                                                    // @ts-ignore 
                                                    b += Object.keys(res.allegati[0]).length;

                                                }
                                                console.log(b);

                                                if (b > 0) {
                                                    Toast.fire({
                                                        title: 'ci sono ' + b + ' Errori nella compilazione',
                                                        icon: 'error'
                                                    });
                                                } else {
                                                    document.getElementById('spinner').style.display = 'inline';
                                                    document.getElementById('_conferma').style.display = 'none';
                                                }

                                            });

                                            console.log(formik.errors);

                                            formik.submitForm();

                                        }}>Invia</button></div>
                                </div>
                            </>

                        </div>}
                    </div>)}
            </Formik>
            }

        </>
    )
}



export default aziendeEducamediamoci;
import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Faq = () => {

    const aprimodal = () => {

        document.getElementById('nuovafaq').style.display = "inline";
    }

    const inviadomanda = () => {

        const fd = new FormData();

        // @ts-ignore
        fd.append('email', document.getElementById('email').value);
        // @ts-ignore
        fd.append('testodomanda', document.getElementById('testodomanda').value);

        axios.post(API_URL_LARAVEL + '/faq', fd
        ).then((response) => {

            console.log(response);
            if (response.data.success) {
                Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });

                document.getElementById('resultfaq').innerHTML = response.data.text;
                document.getElementById('buttfaq').style.display = "none";

            }
        }).catch((err) => {
            console.log(err);
            alert('Errore imprevisto');
        });

        // document.getElementById('nuovafaq').style.display = "inline";
    }

    return (
        <>
            <section id="articolo-dettaglio-testo">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="card-wrapper card-space">
                            <div className="card card-bg"><div className="card-body">
                                <div className="articolo-paragrafi"><div className="row">
                                    <div ><a id="articolo-par-competenze"> </a><h4>Faq
                                    </h4>
                                    </div></div>
                                    <div className="row"><div >
                                        <p>
                                        <br /> <strong> Come dev’essere presentata la domanda?</strong>  <br />
                                            La domanda deve essere presentata unicamente per via telematica
                                            <br /> <strong>Quando scade la presentazione della Domanda?</strong>  <br />
                                            La scadenza della presentazione della rendicontazione è perentoriamente entro e non oltre il 28/01/2025,
                                            alle ore 23:59.
                                            <br />  <strong>Quando verranno erogati i contributi?</strong>  <br />
                                            I contributi verranno erogati a tutti i soggetti beneficiari indicativamente in un’unica soluzione entro il 30/04/2025.
                                            <br />  <strong>Perché non riesco a chiudere la presentazione della Domanda?</strong>  <br />
                                            Perchè non sono stati compilati tutti i campi obbligatori.
                                            <br /> <strong>Cosa devo allegare alla domanda?</strong>  <br />
                                            Alla domanda dovranno essere allegati:
                                            <br /> Copia del documento di identità del richiedente in corso di validità;
                                            <br /> Copia Attestazione ISEE 2023 in corso di validità oppure ISEE corrente, o, nel caso di nuclei familiari con figli minori, i cui genitori siano separati legalmente, non siano coniugati e non convivano, occorre essere in possesso di ISEE minorenni in corso di validità, nel rispetto delle vigenti normative in materia di ISEE.
                                            <br />  Dichiarazione attestante la composizione del proprio nucleo familiare;
                                            <br />  Per i minori con disabilità, copia del verbale attestante riconoscimento disabilità del minore grave certificata, L.104/92 c.3 (o autocertificazione resa ai sensi dell’art art. 46 D.P.R.28 dicembre 2000 n. 445);
                                            <br />  Copia di documentazione valida, rilasciata dalla struttura in cui si sono svolte le attività, attestante la partecipazione del/i minore/i, nel periodo compreso tra l’1 giugno e il 31 dicembre 2024, ad una delle suddette attività, la relativa spesa sostenuta e contenente tutti i dati necessari ad identificare la tipologia di soggetto erogatore;
                                            <br />  Dichiarazione sostitutiva di certificazione.
                                            <br />  <strong>Entità del contributo?</strong>  <br />
                                            All’utente sarà riconosciuto, per ogni minore di età compresa tra i 3 e i 17 anni componente il nucleo, un contributo finalizzato al totale o parziale rimborso delle spese pagate per la frequenza del minore alle attività organizzate dai centri estivi diurni, dai servizi socioeducativi territoriali e dai centri con funzione educativa e ricreativa, nel periodo 01 giugno – 31 dicembre 2024, tenendo conto dell’attestazione ISEE 2024, in corso di validità o minorenni o corrente.
                                            <br /> Il contributo massimo erogabile non possa superare l’importo complessivo di € 100,00 a settimana, fino ad un massimo di euro 800,00 per ogni minore e che la percentuale di contribuzione debba essere differenziata sulla base del valore ISEE nel rispetto dei seguenti scaglioni:
                                            <br />  a) i genitori con reddito ISEE in corso di validità inferiore o uguale alla soglia di euro 20.000,00. L’amministrazione comunale sosterrà le famiglie che rientrano in tale categoria con l’erogazione di un contributo pari al 100% del costo del servizio;
                                            <br /> b) i genitori con reddito ISEE in corso di validità da euro 20.000,01 a euro 30.000,00.
                                            <br /> L’amministrazione comunale sosterrà le famiglie che rientrano in tale categoria con l’erogazione di un contributo pari al 90% del costo del servizio;
                                            <br /> c) i genitori con reddito ISEE in corso di validità da euro 30.000,01 a euro 40.000,00.
                                            <br />  L’amministrazione comunale sosterrà le famiglie che rientrano in tale categoria con l’erogazione di un contributo pari al 80% del costo del servizio;
                                            <br />  La soglia reddituale ISEE, su indicata, non si applica in caso di minori iscritti diversamente abili.
                                            <br />  <strong>Se il mio nucleo familiare è composto da più minorenni quanti contributi potrò ottenere?</strong>  <br />
                                            Il contributo verrà assegnato per ogni minore dai 3 ai 17 anni, appartenente al nucleo familiare, a rimborso della spesa effettivamente sostenuta, documentata e quietanzata, derivanti dall’effettiva e regolare frequenza della struttura/servizio presente nel territorio sarà assegnato, fino ad esaurimento dei fondi, in ordine alla graduatoria stilata sulla base crescente del valore ISEE del nucleo familiare e, a parità di valore ISEE, con priorità ai nuclei con minori con disabilità e, successivamente, ai nuclei con il maggior numero di figli minori
                                            <br /> <strong>Quali sono i criteri per ricevere il contributo?</strong>  <br />
                                             I. Frequenza del minore ad uno dei servizi in epigrafe;
                                            <br /> II. Residenza del minore nel Comune di Rende dalla data di inizio di fruizione del servizio;
                                            <br />  III. ISEE ORDINARIO O CORRENTE, NON SUPERIORE ad € 40.000,00. Nel caso di nuclei familiari con figli minori, i cui genitori siano separati legalmente, non siano coniugati, non convivano, occorre essere in possesso di ISEE minorenni in corso di validità, nel rispetto delle vigenti normative in materia di ISEE;
                                            <br /> IV. Non beneficiare, per lo stesso periodo di riferimento e per il medesimo minore per cui si chiede il rimborso, di contributi per la stessa finalità erogati dall’INPS o da altro Ente per il quale dovrà essere esibita idonea autocertificazione ai sensi del D.P.R. n.445/2000 (a titolo esemplificativo e non esaustivo: buoni servizio regionali, contributi centri estivi INPS ecc.);
                                            <br />  V. Possesso di documentazione valida, attestante, nel periodo ricompreso tra giugno e dicembre 2024, la partecipazione del/i minore/i ad una delle suddette attività, la relativa spesa sostenuta e contenente tutti i dati necessari ad identificare la tipologia di soggetto erogatore. Tutte le spese non giustificabili con idonea documentazione, comprovante l’effettivo pagamento delle prestazioni, non saranno rimborsabili
                                            <br /> <strong>Come verranno erogati i contributi?</strong>  <br />
                                            Il contributo verrà erogato direttamente alle famiglie tramite accredito sul conto corrente bancario corrispondente al Codice IBAN del richiedente indicato nella domanda. La mancata o inesatta indicazione del Codice IBAN da parte del richiedente, relativo esclusivamente a un conto corrente bancario, postale o ad una carta POSTE PAY EVOLUTION, comporterà l’impossibilità di procedere al pagamento del contributo (ATTENZIONE l’IBAN DEI LIBRETTI POSTALI non è idoneo a ricevere il bonifico).
                                            <br /> <strong>Verranno effettuati controlli?</strong>  <br />
                                            Sì, come previsto dalla Legge, su un campione del 10% delle rendicontazioni pervenute.
                                            <br />  <strong>Se ho sbagliato un dato nella Domanda come devo fare?</strong>  <br />
                                            Deve contattare il settore servizi sociali.
                                            <br />  <strong>I miei bambini hanno partecipato a più attività posso allegare tutto o devo sceglierne solo una?</strong>  <br />
                                            Si può allegare tutta la documentazione riferita alle varie attività.
                                            <br /> <strong>Che cosa si intende per dichiarazione attestante la composizione del proprio nucleo familiare?</strong>  <br />
                                            Bisogna effettuare una dichiarazione attestante la composizione del proprio nucleo familiare ai sensi degli artt. 46 e 47 del DPR 28 dicembre 2000, n. 445, sotto la propria responsabilità e nella consapevolezza delle conseguenze penali in caso di dichiarazione mendace, formazione o uso di atti falsi e della decadenza dai benefici ottenuti sulla base di dichiarazioni non veritiere.
                                            <br />  <strong>La copia rilasciata dalla struttura ha un format preciso o può essere scritta dall’erogatore del servizio in forma libera. Inoltre è possibile ricevere altri contributi?</strong>  <br />
                                            Deve essere allegata documentazione valida, attestante la partecipazione del/i minore/i ad una delle attività indicate nell'avviso pubblico, il periodo ricompreso tra 1 giugno e 31 dicembre 2024, la relativa spesa sostenuta e contenente tutti i dati necessari ad identificare la tipologia di soggetto erogatore. Per lo stesso periodo di riferimento e per il medesimo minore per cui si chiede il rimborso, non è possibile beneficiare, di contributi per la stessa finalità erogati dall’INPS o da altro Ente (a titolo esemplificativo e non esaustivo: buoni servizio regionali, contributi centri estivi INPS ecc.).
                                            <br /> <strong>Come posso verificare se ho inserito tutti i dati correttamente?</strong>  <br />
                                            Il primo controllo viene eseguito in modo automatico nella procedura di inserimento dei dati.
                                            <br />  <strong>I 3 anni devono essere compiuti alla data del 01/06/2024 oppure hanno diritto al contributo tutti i bimbi nati nell''anno 2021?</strong>  <br />
                                            Il minore deve aver compiuto 3 anni quando ha iniziato l'attività.
                                            <br /> <strong>Riguardo al punto C, è possibile presentare regolare certificato di stato di famiglia?</strong>  <br />
                                            Si, si può allegare il certificato di stato di famiglia alla documentazione
                                            <br /> <strong>Come posso modificare una domanda già inoltrata</strong>  <br />
                                            La domanda inoltrata non può essere corretta. Si può inviare una nuova domanda.

                                            <br />
                                            <br />
                                            <div>
                                                {/* Button trigger modal */}
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        aprimodal();
                                                    }}
                                                >Hai altre domande?</button>
                                                {/* Modal */}
                                                <div id="nuovafaq" style={{ 'display': 'none' }}>
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h2 className="modal-title h5 no_toc" id="myInput">Invia una domanda</h2>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor="email" >Inserisci Email</label>
                                                                        <input type="email" className="form-control" id="email" required data-focus-mouse="false" aria-invalid="false" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor="testodomanda" >Testo descrizione</label>
                                                                        <textarea id="testodomanda" rows={3} data-focus-mouse="false" defaultValue={""} />
                                                                    </div>
                                                                </div>

                                                                <button id="buttfaq" type="button" className="btn btn-primary"
                                                                    onClick={() => {
                                                                        inviadomanda();
                                                                    }}
                                                                >Invia Domanda</button>
                                                            </div>

                                                            <h5 style={{ padding: 15 }} id="resultfaq"></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div></div></div></div></div></div></div></div>
            </section>
        </>
    )
}

export default Faq;
import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";

type DomandaFields = {
    nome: string,
    cognome: string,
    cf: string,
    pec:string,
    iban: string,
    gestante: string,
    intervento: string,
    comunenascita: string,
    comuneresidenza: string,
    componenti: string,
    disoccupatonucleoanno: string,
    disoccupatonucleo: string,
    occupatinucleo: string,
    minori03: string,
    minori: string,
    figli: string,
    qualita:string,
    allegati: []


}

const http = axios.create({
    baseURL: API_URL_LARAVEL,
    headers: {
        "Content-type": "application/json"
    }
});




const MAX_FILE_SIZE = 2000400; //100KB

const validFileExtensions = { image: ['jpg', 'pdf', 'tif', 'png', 'jpeg'] };

const yupSchema = Yup.object().shape({
    nome: Yup.string().required('Nome è richiesto').min(2, "inserire almeno 2 caratteri"),
    cognome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    comuneresidenza: Yup.string().required('Obbligatorio'),
    iban: Yup.string().required('Obbligatorio').matches(
        /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/,
        "Iban non conforme"
    ),
    componenti: Yup.number()
        .required('ERROR: Compilazione richiesta!')
        .test(
            'selezionare',
            'ERROR: diverso da 0!',
            (value) => value > 0
        ),

    pec: Yup.string().email().matches(/^(?!.*@[^,]*,)/),

    intervento: Yup.string().required('Obbligatorio'),
    gestante: Yup.string().required('Obbligatorio'),
    disoccupatonucleoanno: Yup.string().required('Obbligatorio'),
    disoccupatonucleo: Yup.string().required('Obbligatorio'),
    occupatinucleo: Yup.string().required('Obbligatorio'),
    minori03: Yup.string().required('Obbligatorio'),
    minori: Yup.string().required('Obbligatorio'),
    figli: Yup.string().required('Obbligatorio'),
    qualita:Yup.string().required('Obbligatorio'),


 
})


let valoriIniziali: DomandaFields = {

    nome: "",
    pec: "",
    cognome: "",
    intervento: "",
    cf: "",
    iban: "",
    gestante: "",
    comunenascita: "",
    comuneresidenza: "",
    componenti: "",
    disoccupatonucleoanno: "",
    disoccupatonucleo: "",
    occupatinucleo: "",
    minori03: "",
    minori: "",
    qualita: "",
    figli: "",
    allegati: []


    //*/
}
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

let dati = {};


const Domanda = () => {

    const params = useParams();

    const location = useLocation();
    const [visual, setsetvisual] = useState(false)

    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);

    }

    function getdatidomanda(id: any) {
        // @ts-ignore 
        http.get(`getIstanza/${id}`).then(response => {
            // @ts-ignore            
            valoriIniziali.nome = response.data.nome;
            valoriIniziali.cognome = response.data.cognome;

            valoriIniziali.comunenascita = response.data.comunenascita;
            valoriIniziali.cf = response.data.cf;


            // @ts-ignore 
            setsetvisual(true);
        }).catch((reason) => {
            Toast.fire({
                title: 'ID DOMANDA non trovato',
                icon: 'error'
            });
        });

    }

    useEffect(() => {

        if (params.id != '0') {

            getdatidomanda(params.id)
        } else {

            let idinse = prompt("Inserisci Id domanda presente sulla Ricevuta:", "");


            window.location.href = "/SostegnoFamiglie2021Integrazione/" + idinse;
            //66665 getdatidomanda(idinse)
        }
    },);

    return (
        <> <h1> Integrazione Domande  </h1>
            {visual && <> <Formik initialValues={valoriIniziali}
                validationSchema={yupSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {

                    const fileInput = document.querySelectorAll('input[type="file"]');

                    //   console.log(fileInput);
                    const data = JSON.stringify({ ...values })
                    const fd = new FormData();
                    // append directly as part of the postData in plain text
                    fd.append('istanzajson', data);
                    fd.append('identificativo', "1099142");
                    fd.append('note', params.id);
               
                    fd.append('nome', "Integrazione - Fondoannualità 2021");

                    fileInput.forEach((docItem) => {
                        fd.append(docItem.id, (document.getElementById(docItem.id) as HTMLInputElement).files[0]);
                    });


                    axios.post(API_URL_LARAVEL + '/istanza', fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    ).then((response) => {

                        console.log(response);

                        if (response.data.success) {
                            Toast.fire({
                                title: response.data.text,
                                icon: 'success'
                            });
                            window.location.href = "/esito?id=" + response.data.result;

                        }
                    }).catch((err) => {
                        console.log(err);

                        alert('Errore imprevisto.');

                        document.getElementById('spinner').style.display = 'none';
                        document.getElementById('_conferma').style.display = 'inline';

                    });

                    //    alert(JSON.stringify(values));
                }}>
                {(formik: FormikProps<any>) => (
                    <div className="form">

                        <table>
                            <tbody><tr>
                                <td>
                                    <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                        <li className="active">
                                            Richiesta di ammissione al bando PER L’EROGAZIONE STRAORDINARIA DI
                                            BENEFICI ECONOMICI A SOSTEGNO DELLA FAMIGLIA - Fondo
                                            annualità 2021
                                        </li>
                                    </ol>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <p>

                            Gentile  {valoriIniziali.nome} {valoriIniziali.cognome} nata a {valoriIniziali.comunenascita} e identificata con codice fiscale :  {valoriIniziali.cf} si chiede il rinvio di alcuni dati per integrazione
                            per la domanda con ID = {params.id}

                        </p>

                        {<div id="div_domanda">

                            <div className="row">

                                <div className="form-group">
                                    <label htmlFor="comuneresidenza">Comune Residenza </label>
                                    <br /> <br />
                                    <select style={{ border: 'solid 1px' }} onChange={event => formik.setFieldValue('comuneresidenza', event.target.value)} className="form-control" id="comuneresidenza" name="comuneresidenza">
                                        <option value="">Seleziona ..</option>
                                        <option value="Castiglione Cosentino">Castiglione Cosentino</option>
                                        <option value="Castrolibero">Castrolibero</option>
                                        <option value="Marano Marchesato">Marano Marchesato</option>
                                        <option value="Marano Principato">Marano Principato</option>
                                        <option value="Rende">Rende</option>
                                        <option value="Rose">Rose</option>
                                        <option value="San Fili">San Fili</option>
                                        <option value="San Pietro In Guarano">San Pietro In Guarano</option>
                                        <option value="San Vincenzo La Costa">San Vincenzo La Costa</option>
                                    </select>
                                </div>
                            </div>
                            <small className="error">  <ErrorMessage name='comuneresidenza' /> </small>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Recapiti</div></div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='pec'>pec</label>
                                        <input type="text" className="form-control" name='pec' id='pec' onChange={event => formik.setFieldValue('pec', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='pec' /> </small>
                                    </div>
                                </div>

                            </div>
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>CHIEDE L’AMMISSIONE AL
                                BENEFICIO:
                            </div></div>
                            <div>
                                <div className="form-check">

                                    <input id="intervento1" type="radio" name="intervento" onChange={event => formik.setFieldValue('intervento', 'intervento1')} />
                                    <label htmlFor="intervento1" >INTERVENTO 1- Sostegno e/o incremento al reddito - erogazione di contributi in
                                        denaro a favore di nuclei familiari in situazioni di particolare criticità per il
                                        raggiungimento del minimo reddituale (contributo una tantum);</label>
                                    <br />
                                    <input id="intervento2" type="radio" name="intervento" onChange={event => formik.setFieldValue('intervento', 'intervento2')} />
                                    <label htmlFor="intervento2" > INTERVENTO 2 – Sostegno e/o incremento al reddito erogazione di contributi in
                                        denaro a favore di nuclei familiari in situazioni di particolare criticità al cui interno sono
                                        presenti gestanti e/o minori da 0/3 anni, per il raggiungimento del minimo reddituale
                                        (contributo una tantum); </label>

                                </div>

                                <small className="error">  <ErrorMessage name='intervento' /> </small>
                                <br />
                                <strong>In qualità di</strong>
                                <div className="form-check">

                                    <input id="genitore" type="radio" name="qualita" onChange={event => formik.setFieldValue('qualita', 'genitore')} />
                                    <label htmlFor="genitore" > Genitore</label>
                                 
                                    <input id="tutore" type="radio" name="qualita" onChange={event => formik.setFieldValue('qualita', 'tutore')} />
                                    <label htmlFor="tutore" >Tutore </label>
                                 
                                    <input id="altri" type="radio" name="qualita" onChange={event => formik.setFieldValue('qualita', 'altri')} />
                                    <label htmlFor="altri" >Altro </label>

                                </div>

                                <small className="error">  <ErrorMessage name='qualita' /> </small>
                            </div>

                            {<>
                                <br />
                                <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>DICHIARA</div></div>

                                <p>Consapevole delle sanzioni penali previste in caso di dichiarazioni mendaci, falsità negli atti e uso di

                                    atti falsi (ART. 76 D.P.R. 445/28.12.2000)</p>

                                {formik.getFieldProps('istanzadoppia') && <>

                                    <div className="form-check">

                                        Che il nucleo familiare destinatario per cui si chiede il servizio è composto da n.   &nbsp;&nbsp;

                                        <select id="componenti" onChange={event => formik.setFieldValue('componenti', event.target.value)}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                              <option value={7}>7 o oltre</option>
                                        </select>
                                        &nbsp;&nbsp;   componenti;

                                    </div>
                                    <small className="error">  <ErrorMessage name='componenti' /> </small>

                                    <div className="form-check">
                                        <p>Che nel nucleo è presente una gestante</p>

                                        <div className="row">

                                            <div className="col-sm" style={{ maxWidth: 150 }}>
                                                <div className="form-check">
                                                    <input id="gestante" type="radio" name="gestante" onChange={event => formik.setFieldValue('gestante', 'SI')} />
                                                    <label htmlFor="gestante" > SI</label>

                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-check">
                                                    <input id="gestanteno" type="radio" name="gestante" onChange={event => formik.setFieldValue('gestante', 'NO')} />
                                                    <label htmlFor="gestanteno" > NO</label>

                                                </div>
                                            </div>
                                        </div>
                                        <small className="error">  <ErrorMessage name='gestante' /> </small>
                                    </div>


                                    <div className="form-check">

                                        Che il nucleo familiare destinatario per cui si chiede il servizio è composto da n.   &nbsp;&nbsp;

                                        <select id="figli" onChange={event => formik.setFieldValue('figli', event.target.value)}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                              <option value={7}>7 o oltre</option>
                                        </select>
                                        &nbsp;&nbsp;   figli
                                        maggiorenni a carico;

                                        <small className="error">  <ErrorMessage name='figli' /> </small>


                                    </div>

                                    <div className="form-check">

                                        Che all’interno del nucleo vi sono n.   &nbsp;&nbsp;

                                        <select id="minori" onChange={event => formik.setFieldValue('minori', event.target.value)}>
                                            <option selected value="">Scegli un'opzione </option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                              <option value={7}>7 o oltre</option>
                                        </select>
                                        &nbsp;&nbsp;   minori;

                                        <small className="error">  <ErrorMessage name='minori' /> </small>

                                    </div>

                                    <div className="form-check">
                                        Che all’interno del nucleo vi sono n.   &nbsp;&nbsp;
                                        <select id="minori03" onChange={event => formik.setFieldValue('minori03', event.target.value)}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                              <option value={7}>7 o oltre</option>
                                        </select>
                                        &nbsp;&nbsp;   minori 0-3 anni;

                                        <small className="error">  <ErrorMessage name='minori03' /> </small>
                                    </div>
                                    <br />
                                    <strong>Che la condizione lavorativa del nucleo familiare è la seguente:</strong>
                                    <br />
                                    <div className="form-check">
                                        Indicare il numero di componenti occupati del nucleo: n.   &nbsp;&nbsp;
                                        <select id="occupatinucleo" onChange={event => formik.setFieldValue('occupatinucleo', event.target.value)}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                              <option value={7}>7 o oltre</option>
                                        </select>
                                        &nbsp;&nbsp; ;
                                        <small className="error">  <ErrorMessage name='occupatinucleo' /> </small>
                                    </div>


                                    <div className="form-check">
                                        Genitore (o facente funzione o richiedente) disoccupato da meno di 1 anno dalla data di pubblicazione del bando
                                        n.  &nbsp;&nbsp;
                                        <select id="disoccupatonucleo" onChange={event => formik.setFieldValue('disoccupatonucleo', event.target.value)}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>

                                        </select>
                                        &nbsp;&nbsp; ;
                                        <small className="error">  <ErrorMessage name='disoccupatonucleo' /> </small>
                                    </div>


                                    <div className="form-check">

                                        Genitore (o facente funzione  o richiedente) disoccupato da oltre 1 anno dalla data di pubblicazione del bando n.  &nbsp;&nbsp;
                                        <select id="disoccupatonucleoanno" onChange={event => formik.setFieldValue('disoccupatonucleoanno', event.target.value)}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>

                                        </select>
                                        &nbsp;&nbsp; ;
                                        <small className="error">  <ErrorMessage name='disoccupatonucleo' /> </small>
                                    </div>


                                    <br />
                                    <p>Il codice Iban deve essere obbligatoriamente intestato o cointestato al dichiarante</p>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor='iban'>Iban</label>
                                                <input type="text" className="form-control" name='iban' id='iban' onChange={event => formik.setFieldValue('iban', event.target.value)} />
                                                <small className="error">  <ErrorMessage name='iban' /> </small>

                                            </div>
                                        </div>
                                    </div>

                                                           


                                    <div style={{ display: 'none' }} id='spinner' className="progress progress-indeterminate">
                                        <span className="visually-hidden">In elaborazione...</span>
                                        <div className="progress-bar" role="progressbar" />
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <div className="btn-group col-sm-5 col-sm-offset-6 ">
                                            <button name="_anteprima" id="_anteprima" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150, display: 'none' }}

                                                onClick={() => {
                                                    stampa_id('root');
                                                    console.log(formik.errors);
                                                }}

                                            >Anteprima</button>
                                            {/**   <button name="_stampadomanda" id="_stampadomanda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Stampa domanda</button>
                                   */}  <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }} onClick={() => {

                                                // alert('invio');

                                                formik.validateForm().then(async res => {
                                                    const { ob } = await res;

                                                    let b = Object.keys(res).length;


                                                    //       console.log('res', b, res);

                                                    //     console.log(b);


                                                    console.log(b);


                                                    if (b > 0) {
                                                        Toast.fire({
                                                            title: 'ci sono ' + b + ' Errori nella compilazione',
                                                            icon: 'error'
                                                        });
                                                    } else {
                                                        document.getElementById('spinner').style.display = 'inline';
                                                        document.getElementById('_conferma').style.display = 'none';

                                                    }


                                                });

                                                console.log(formik.errors);

                                                formik.submitForm();

                                            }}>Invia</button></div>


                                    </div>
                                </>}
                            </>
                            }
                        </div>}
                    </div>)}
            </Formik >
            </>}


        </>
    )
}

export default Domanda;
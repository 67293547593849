import axios from "axios";
import { useState, useEffect } from 'react';
import { ErrorMessage, Field, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import AuthUser from './AuthUser';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";
import { useNavigate } from 'react-router-dom';
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})


type Fields = {
    codice: string,
}
const yupSchema = Yup.object().shape({
    codice: Yup.string().required('Obbligatorio')
})

const valoriIniziali: Fields = {
    codice: "",
}





const Verifiche = () => {
    const user = AuthUser().user;
    const [verifica, setverifica] = useState(false);
    const [saldo, setsaldo] = useState(0);
    const [beneficiario, setbeneficiario] = useState('');

    type Fields2 = {
        tipoacquisto: string,
        acquisto: number,
    }
    const yupSchema2 = Yup.object().shape({
        tipoacquisto: Yup.string().required('Obbligatorio'),
        acquisto: Yup.number().required('obbligatorio').test(
            'Is positive?',
            'ERRORE: Valore maggiore di zero e minore o uguale al valore residuo!',
            (value) => value > 0 && value <= saldo
        ),

        beneficiario: Yup.string().required('obbligatorio').test(
            'rispondere alla domanda',
            'ERRORE:Risposta non corretta!',
            (value) => String(value).toUpperCase() == String(beneficiario).toUpperCase()
        )
    })

    const valoriIniziali2: Fields2 = {
        tipoacquisto: "",
        acquisto: 0
    }

    return (<> 
     Procedura Completata e Chiusa
          {false &&  <div className="row justify-content-center pt-5">
                <div className="col-sm-8">
                    <div className="card p-8">
                        <h3>Verifica Voucher</h3>

                        <br />
                        <>

                            <Formik initialValues={valoriIniziali}
                                validationSchema={yupSchema}
                                validateOnBlur={false}
                                validateOnChange={false}
                                onSubmit={(values) => {

                                    axios.post(API_URL_LARAVEL + '/verificavoucher', values, {
                                        headers: {
                                            "Content-Type": "multipart/form-data"
                                        }
                                    }
                                    ).then((response) => {
                                        console.log(response);
                                        if (response.data.success) {
                                            Toast.fire({
                                                title: response.data.text,
                                                icon: 'success'
                                            });

                                            setverifica(true);
                                            setbeneficiario(response.data.voucher[0].nome_assegnatario);
                                            setsaldo(response.data.voucher[0].valore_iniziale - response.data.voucher[0].valore_speso);
                                            // @ts-ignore
                                            document.getElementById('acquisto').value = 0;
                                            // @ts-ignore
                                            document.getElementById('tipoacquisto').value = '';
                                        } else {
                                            setverifica(false);
                                            Toast.fire({
                                                title: response.data.text,
                                                icon: 'error'
                                            });
                                        }

                                    }).catch((err) => {
                                        Toast.fire({
                                            title: 'Errore',
                                            icon: 'error'
                                        });
                                    });

                                    //    alert(JSON.stringify(values));
                                }}>
                                {(formik: FormikProps<any>) => (

                                    <div className="form">
                                        <div className="row">
                                            <div className="col-sm-2">

                                                <Field component={InputField} id='codice' label='Inserire Codice' />
                                            </div>
                                            <div className="col-sm-2"></div>
                                            <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-sm pull-left" style={{ width: 150, height: 50 }}
                                                onClick={() => {
                                                    formik.validateForm().then(async res => {

                                                        formik.submitForm();
                                                        console.log(formik.errors);

                                                    });
                                                }}>Verifica</button>
                                        </div>

                                    </div>)
                                }
                            </Formik >

                        </>


                    </div>


                    {verifica && <>

                        <Formik initialValues={valoriIniziali2}
                            validationSchema={yupSchema2}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={(values) => {

                                const fd = new FormData();
                                // append directly as part of the postData in plain text
                                fd.append('iduser', user.id);
                                // @ts-ignore
                                fd.append('valore', document.getElementById('acquisto').value);
                                // @ts-ignore
                                fd.append('tipoacquisto', document.getElementById('tipoacquisto').value);
                                // @ts-ignore
                                fd.append('codice', document.getElementById('codice').value);

                                axios.post(API_URL_LARAVEL + '/acquisto', fd, {
                                    headers: {
                                        "Content-Type": "multipart/form-data"
                                    }
                                }
                                ).then((response) => {
                                    console.log('response', response);
                                    if (response.data.success) {
                                        Toast.fire({
                                            title: response.data.text,
                                            icon: 'success'
                                        });

                                        // @ts-ignore
                                        document.getElementById('acquisto').value = 0;
                                        // @ts-ignore
                                        document.getElementById('tipoacquisto').value = '';
                                        // @ts-ignore
                                        document.getElementById('beneficiario').value = '';
                                        //    setverifica(true);
                                        setsaldo(response.data.voucher.valore_iniziale - response.data.voucher.valore_speso);

                                    } else {
                                        setverifica(false);
                                        Toast.fire({
                                            title: response.data.text,
                                            icon: 'error'
                                        });
                                    }

                                }).catch((err) => {

                                    console.log(err);
                                    Toast.fire({
                                        title: 'Errore',
                                        icon: 'error'
                                    });
                                });

                                //    alert(JSON.stringify(values));
                            }}>
                            {(formik2: FormikProps<any>) => (

                                <div className="form">

                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label className="active" htmlFor="residuo">Valore Residuo</label>
                                                <input type="text" id="residuo" name="residuo" value={saldo} />
                                            </div>
                                        </div>

                                        <div className="col-sm">
                                            <div className="form-group">
                                                <div className="select-wrapper">
                                                    <small className="error">  <ErrorMessage name='tipoaquisto' /> </small>
                                                    <label htmlFor="tipoacquisto">Selezionare voce di Spesa</label>
                                                    <select id="tipoacquisto" name="tipoacquisto" onChange={event => formik2.setFieldValue('tipoacquisto', event.target.value)}>
                                                        <option selected value="">Scegli un'opzione</option>
                                                        <option value="Libri">Libri</option>
                                                        <option value="Dizionari">Dizionari</option>
                                                        <option value="Cancelleria">Cancelleria</option>
                                                        <option value="Attrezzatura Informatica">Attrezzatura Informatica</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">

                                                <label className="active" htmlFor="acquisto">Valore Nuovo Acquisto</label>
                                                <input type="number" className="form-control" name='acquisto' id='acquisto' onChange={event => formik2.setFieldValue('acquisto', event.target.value)} />
                                                <small className="error">  <ErrorMessage name='acquisto' /> </small>
                                            </div>
                                        </div>



                                        <div className="col-sm">
                                            <div className="form-group">

                                                <label className="active" htmlFor="beneficiario">Primo Nome del Beneficiario (studente)</label>
                                                <input type="text" className="form-control" name='beneficiario' id='beneficiario' onChange={event => formik2.setFieldValue('beneficiario', event.target.value)} />
                                                <small className="error">  <ErrorMessage name='beneficiario' /> </small>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">

                                            <button name="salva" id="salva" value="true" type="button" className="btn btn-primary btn-sm pull-left" style={{ width: 150, height: 50 }}
                                                onClick={() => {
                                                    formik2.submitForm();
                                                    console.log('errore', formik2.errors);

                                                 //   formik2.validateForm();
                                                    if (Object.keys(formik2.errors).length > 0) {
                                                        alert('Tutti i campi sono Obbligatori');
                                                    }
                                                }}>Salva Acquisto</button>
                                        </div>
                                    </div>

                                </div>
                            )
                            }
                        </Formik >


                    </>}
                </div>
            </div>
            } 
        </>
    )
}

export default Verifiche;
import axios from "axios";
import React, { Component, useEffect, useState } from 'react';
import { useLocation, useParams,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_URL_LARAVEL } from "../config/config";



const http = axios.create({
    baseURL: API_URL_LARAVEL,
    headers: {
        "Content-type": "application/json"
    }
});

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

let dati = {};


const Domanda = () => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [visual, setsetvisual] = useState(false)

    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);

    }

    function getdatidomanda(id: any) {
        // @ts-ignore 
        http.get(`getIstanza/${id}`).then(response => {

            console.log('IDGETISTANZ',response.data);
                  
       
            navigate('/singolaistanza', { state: { identificativo: '109914', datainvio: "2024-11-06T11:20:15.000000Z", json: response.data, id: id, note: '', esito: '' } })
           
        }).catch((reason) => {

            console.log(reason);

            Toast.fire({
                title: 'ID DOMANDA non trovato',
                icon: 'error'
            });
        });

    }

    useEffect(() => {

        if (params.id != '0') {

            getdatidomanda(params.id)
        } else {

            let idinse = prompt("Inserisci Id domanda presente sulla Ricevuta:", "");


            window.location.href = "/SostegnoFamiglie2021Integrazione/" + idinse;
            //66665 getdatidomanda(idinse)
        }
    },);

    return (
        <> <h1> Domanda   </h1>
           </>
    )
}

export default Domanda;